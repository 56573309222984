import amplitude from 'amplitude-js';

// Domain name
const amplitudeKey = process.env.REACT_APP_AMPLITUDE_API_KEY || '5fabf90ea39e419032cd5dafed09a651';
const domainName = window.location.hostname;

class EventService {
  constructor() {
    amplitude.getInstance().init(amplitudeKey);
  }

  /**
   * Logs the event to the backend platform.
   * @param {String} eventName The name of the event that needs to be logged.
   * @param {Object} eventData The properties that needs to be logged for the event.
   */
  logEvent(eventName, eventData, userProperties) {
    eventData.domain = domainName;
    // console.log(`Event: ${eventName}==> ${JSON.stringify(eventData)}`);
    // Gets the instance of amplitude and logs the event against it.
    if(userProperties != null) {
      amplitude.getInstance().setUserProperties(userProperties);
    }
    amplitude.getInstance().logEvent(eventName, eventData);
  }

  withEvents(Component) {
    function ComponentWithUserEffects(props) {
      return (
        <Component {...props}/>
      );
    }
    return ComponentWithUserEffects;
  }
}

export default new EventService();