import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
  const user = useSelector((state) => state.user);
  const location = useLocation();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/" replace state={{ path: location.pathname }} />;
  }
  return children;
};