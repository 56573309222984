const PERSISTANT_TYPE_KEY= '___PRAY_PERSISTANT_TYPE';
// This flag defines if the storage is local or at the session level.
const storageType = (localStorage.getItem(PERSISTANT_TYPE_KEY) == null)
    ? 'true' : localStorage.getItem(PERSISTANT_TYPE_KEY);

/**
 * Represents the method that writes the data to the local storage.
 * @param {String} key The key that will be used to persist the data in the local storage.
 * @param {String} Value The value that is persisted in the local storage
*/
export function set(key, value) {
    if (storageType === 'true') {
        localStorage.setItem(key, JSON.stringify(value));
    } else if (storageType === 'false') {
        sessionStorage.setItem(key, value);
    }
}

/**
 * Represents the method that gets the data from the local storage.
 * @param {String} key The key that will be used to persist the data in the local storage.
 * @returns {String} Returns the value from the local storage.
*/
export function get(key) {
    if (storageType === 'true') {
        return JSON.parse(localStorage.getItem(key));
    }
    return sessionStorage.getItem(key);
}

/**
 * Represents the method that removes the data from the local storage.
 * @param {String} key The key that will be used to persist the data in the local storage.
*/
export function remove(key) {
    if (storageType === 'true') {
        return localStorage.removeItem(key);
    }
    return sessionStorage.removeItem(key);
}