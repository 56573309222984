     /* eslint-disable require-jsdoc */
import axios from "axios";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const loadSettingsFromService = createAsyncThunk(
  "settings/getusersettings",
  async (artist) => {
    const axiosObj = axios.create({
      baseURL: `https://40da9ks4le.execute-api.us-east-1.amazonaws.com/?handle=${artist}&environment=${process.env.REACT_APP_ENVIRONMENT}`,
      headers: {
        "Content-type": "application/json"
      }
    });
    const res = await axiosObj.get();
    return res.data;
  }
);

const counterSlice = createSlice({
  name: 'settings',
  initialState: { value: 0, loaded: false, analyticsUrl: null },
  extraReducers: {
    [loadSettingsFromService.fulfilled]: (state, action) => {
    },
    [loadSettingsFromService.rejected]: (state, action) => {
      console.log('Rejected');
    },
  }
});

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

// eslint-disable-next-line no-unused-vars
function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export default counterSlice.reducer;