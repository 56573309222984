import { Button } from '@mui/material';
import EventService from '../services/events-service';

/**
 * Represents the custom button wrapper for buttons across the application.
 * @param {Object} props Properties for the component.
 * @return {Object} The view component that would be rendered.
 */
export function PrayButton(props) {
  /**
   * The wrapper method for the click event of the button.
   */
  const onClickEventWrapper = () => {
    if (typeof props.children === 'string' || props.children instanceof String) {
      EventService.logEvent('Tap', { view_name: props.viewName, button_name: props.children });
    } else {
      EventService.logEvent('Tap', { view_name: props.viewName, button_name: props.text });
    }
    if(props.onClick != null) {
      props.onClick();
    }
  };

  /**
   * Returns the components that needs to be rendered.
   */
  return (
    <Button
      style={props.style}
      variant={props.variant}
      disabled={props.disabled}
      className={props.className}
      startIcon={props.startIcon}
      text={props.text}
      sx={props.sx}
      onClick={() => onClickEventWrapper()}>
      {props.children}
    </Button>
  );
};