import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HomeDefaultView } from './module-home';
import { AnalyticsDefaultView, LegacyAnalyticsView } from './module-dashboard';
import { ProtectedRoute } from './common';
import { Typography } from '@mui/material';

/**
 * Document
 * @return {Object} the view
 */
export default function AppRouter() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/dashboard/*" element={<ProtectedRoute><AnalyticsDefaultView /></ProtectedRoute>} />
          <Route path="/analytics/*" element={<ProtectedRoute><AnalyticsDefaultView /></ProtectedRoute>} />
          <Route exact path="/:artist" element={<LegacyAnalyticsView />} />
          <Route exact path="/" element={<HomeDefaultView />} />
        </Routes>
      </Router>
    </div>
  );
};
