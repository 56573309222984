import { createTheme } from "@mui/material";

const PrayAnalyticsTheme = createTheme({
    palette: {
        mode: 'light',
        common: {
            black: '#18191C'
        },
        primary: {
            main: '#18191C',
            light: '#2C2E33',
            dark: '#0B0C0D'
        },
        secondary: {
            main: '#FFFFFF',
            light: '#A6A8AD',
            dark: '#3C3C3D'
        },
        error: {
            main: '#E91E63',
        },
        text: {
            primary: '#3C3C3D',
        }
    },
    typography: {
        fontFamily: 'Poppins',
        fontSize: 13,
        fontWeightRegular: 400,
        fontWeightLight: 300,
        h1: {
            fontWeight: 200
        },
        h2: {
            fontWeight: 200,
        },
        h3: {
            fontWeight: 200
        },
        h4: {
            fontSize: '0.9rem',
            color: '#3C3C3D',
            fontWeight: 600,
        },
        h5: {
            fontSize: '0.8rem',
            color: '#3C3C3D',
            fontWeight: 600,
            opacity: 0.7,
        },
        h6: {
            fontSize: '0.72rem',
            color: '#3C3C3D',
            fontWeight: 500,
            opacity: 0.7,
        },
        subtitle2: {
            fontSize: '0.7rem',
            opacity: 0.7,
        },
        subtitle1: {
            fontSize: '0.85rem',
            color: '#3C3C3D',
            fontWeight: 600,
        },
        button: {
            fontFamily: 'Poppins',
            textTransform: 'none',
            borderRadius: 0,
        }
    }
});

export default PrayAnalyticsTheme;