import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as HttpService from '../../common/services/http-service';

export const loadSettings = createAsyncThunk(
  'settings/getusersettings',
  async () => {
    return await HttpService.securedHttpGet('get-usersettings');
  }
);


const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    notification: 0,
  },
  reducers: {
    updateSetting: (state, action) => { 
      const updatedState = { ...state, ...action.payload };
      // if(updatedState.selectedOrganization == null) {
      //   updatedState.selectedOrganization = updatedState.organizations[0];
      //   // console.log(updatedState.selectedOrganization.features[0].links[0]);
      //   // console.log(updatedState.selectedOrganization);
      // }
      return updatedState;
    }
  },
  extraReducers: {
    // [loadSettings.fulfilled]: (state, action) => {
    //   // console.log(action);
    //   // return {...state, ...action.payload};
    // },
    // [loadSettings.rejected]: (state, action) => {console.log('Rejected');},
  }
});

// this is for dispatch
export const { updateSetting } = settingsSlice.actions;

// this is for configureStore
export default settingsSlice.reducer;