import axios from 'axios';

import * as StorageService from './storage-services';
const baseURL = process.env.REACT_APP_PASTORSAAS_SERVICE_BASE_URL;
const PRAY_AUTH_USER_KEY = 'PRAY_AUTH_USER';

export async function securedHttpGet(url) {
  const userObj = StorageService.get(PRAY_AUTH_USER_KEY);
  const axiosObj = createAxiosInstance(userObj);
  const result = await axiosObj.get(url);
  return result.data;
}

export async function get(url) {
  const axiosObj = createAxiosInstance();
  const result = await axiosObj.get(url);
  return result.data;
}

function createAxiosInstance(userObj = null) {
  const defaultHeaders = { 'Accept': 'application/json', 'Content-Type': 'application/json' };

  // If the access token is passed then the assumption is that it is a secured call. If not the call is expected to be unsecured.
  const axiosObj = axios.create({
    baseURL: `${baseURL}`,
    headers: defaultHeaders
  });

  // Interceptor for the request to add the token.
  axiosObj.interceptors.request.use((config) => {
    if (userObj) {
      config.headers["Authorization"] = 'Bearer ' + userObj.access_token;  // for Spring Boot back-end
    }
    return config;
  },
    (error) => { return Promise.reject(error); }
  );

  // Interceptor for the response to process when the token incase of expired token.
  axiosObj.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      if (err.response.status === 401 && err.response.data !== null && err.response.data.message === 'Invalid auth token was passed.') {
        const originalConfig = err.config;
        originalConfig._retry = true;
        // console.log('*****************');
        try {
          const tokenResponse = await axiosObj.get(`get-token?refresh_token=${userObj.refresh_token}`);
          const { access_token } = tokenResponse.data;
          userObj.access_token = access_token;
          StorageService.set(PRAY_AUTH_USER_KEY, userObj);
          const interval = setInterval(async () => {
            clearInterval(interval);
            window.dispatchEvent(new CustomEvent('token_refreshed'));
            // console.log('token_refreshed invoked');
          }, 5000);
          return axiosObj(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
  );
  return axiosObj;
}
