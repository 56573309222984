import { Link } from 'react-router-dom';
import EventService from '../services/events-service';

/**
 * Represents the custom button wrapper for buttons across the application.
 * @param {Object} props Properties for the component.
 * @return {Object} The view component that would be rendered.
 */
export function PrayLink(props) {
  /**
   * The wrapper method for the click event of the button.
   */
  const onClickEventWrapper = () => {
    if (typeof props.children === 'string' || props.children instanceof String) {
      EventService.logEvent('Tap', { view_name: props.viewName, button_name: props.children });
    } else {
      EventService.logEvent('Tap', { view_name: props.viewName, button_name: props.text });
    }
    if(props.onClick != null) {
      props.onClick();
    }
  };

  /**
   * Returns the components that needs to be rendered.
   */
  return (
    <Link
      style={props.style}
      key={props.key}
      to={props.to}
      rel={props.rel}
      target={props.target}
      onClick={() => onClickEventWrapper()}>
      {props.children}
    </Link>
  );
};