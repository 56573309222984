import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as StorageService from '../services/storage-services';
import * as HttpService from '../services/http-service';

const PRAY_AUTH_USER_KEY = 'PRAY_AUTH_USER';

export const authenticateUser = createAsyncThunk(
  'settings/getusersettings',
  async (code) => {
    const userDetail = await HttpService.get(`/authenticate?code=${code}`);
    return userDetail;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: StorageService.get(PRAY_AUTH_USER_KEY),
  reducers: {
    loginUser: (state, action) => {
      const updatedUserState = { ...state, ...action.payload };
      StorageService.set(PRAY_AUTH_USER_KEY, updatedUserState);
      return updatedUserState;
    },
    logoutUser: (state, action) => {
      StorageService.remove(PRAY_AUTH_USER_KEY);
      return null;
    },
    updateUserDetails: (state, action) => { 
      // console.log('*************');
      const updatedUserState = { ...state, ...action.payload };
      StorageService.set(PRAY_AUTH_USER_KEY, updatedUserState);
      // console.log(updateUserDetails);
      return updatedUserState;
    }
  }
});

// this is for dispatch
export const { loginUser, logoutUser, updateUserDetails } = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;